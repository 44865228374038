import { ThunkDispatch } from "redux-thunk";
import { sceneApi } from "../../api";
import { MessageType, Scene } from "../types";
import { sceneActionTypes } from "./actionTypes";
import { addMessageAsync } from "./messageActions";

export const setScenes = (scenes: Scene[]) => ({
  type: sceneActionTypes.SET_SCENES,
  payload: {
    scenes
  }
});

export const updateScene = (scene: Scene) => ({
  type: sceneActionTypes.UPDATE_SCENE,
  payload: {
    scene
  }
});

export const moveScene = (scene: Scene, newIndex: number) => ({
  type: sceneActionTypes.MOVE_SCENE,
  payload: {
    scene,
    newIndex
  }
});

export const deleteScene = (scene: Scene) => ({
  type: sceneActionTypes.DELETE_SCENE,
  payload: {
    scene
  }
});

export const addCharacterToScene = (sceneId: string, characterId: string) => ({
  type: sceneActionTypes.ADD_CHARACTER_TO_SCENE,
  payload: {
    sceneId,
    characterId
  }
});

export const removeCharacterFromScene = (sceneId: string, characterId: string) => ({
  type: sceneActionTypes.REMOVE_CHARACTER_FROM_SCENE,
  payload: {
    sceneId,
    characterId
  }
});

export const addLocationToScene = (sceneId: string, locationId: string) => ({
  type: sceneActionTypes.ADD_LOCATION_TO_SCENE,
  payload: {
    sceneId,
    locationId
  }
});

export const removeLocationFromScene = (sceneId: string, locationId: string) => ({
  type: sceneActionTypes.REMOVE_LOCATION_FROM_SCENE,
  payload: {
    sceneId,
    locationId
  }
});

export const addItemToScene = (sceneId: string, itemId: string) => ({
  type: sceneActionTypes.ADD_ITEM_TO_SCENE,
  payload: {
    sceneId,
    itemId
  }
});

export const removeItemFromScene = (sceneId: string, itemId: string) => ({
  type: sceneActionTypes.REMOVE_ITEM_FROM_SCENE,
  payload: {
    sceneId,
    itemId
  }
});

export const getScenesAsync = (storyId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result = await sceneApi.getScenes(storyId);

  if (result.statusOk) {
    dispatch(setScenes(result.data));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, result.error));
  }
};

export const moveSceneAsync =
  (storyId: string, oldSceneIndex: number, newSceneIndex: number) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: Function) => {
    const state = getState();
    const scene: Scene | undefined = state?.scenes?.scenes.find((scene: Scene) => scene.sceneIndex === oldSceneIndex);

    if (scene) {
      const updatedScene: Scene = { ...scene, sceneIndex: newSceneIndex };

      dispatch(moveScene(scene, newSceneIndex));
      const result = await sceneApi.updateScene(storyId, updatedScene);

      if (!result.statusOk) {
        dispatch(moveScene(scene, oldSceneIndex));
        dispatch(addMessageAsync(MessageType.ERROR, result.error));
      }
    }
  };

export const addSceneAsync = (storyId: string, index: number) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result = await sceneApi.addScene(storyId, index);

  if (result.statusOk) {
    dispatch(getScenesAsync(storyId));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, result.error));
  }
};

export const updateSceneAsync = (storyId: string, scene: Scene) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result = await sceneApi.updateScene(storyId, scene);

  if (result.statusOk) {
    dispatch(updateScene(scene));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, result.error));
  }
};

export const deleteSceneAsync = (storyId: string, scene: Scene) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result = await sceneApi.deleteScene(storyId, scene);

  if (result.statusOk) {
    dispatch(deleteScene(scene));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, result.error));
  }
};
