import React, { ChangeEvent } from "react";
import style from "./number-input.module.scss";
import classNames from "classnames";

interface NumberInputProps {
  className?: any;
  onChange: (value: number) => void;
  value: number;
  label?: string;
  placeholder?: string;
  borderless?: boolean;
}

export const NumberInput: React.FunctionComponent<NumberInputProps> = (props) => {
  return (
    <div
      className={classNames(
        style.NumberInput,
        {
          [style.NumberInput__borderless]: props.borderless
        },
        props.className
      )}
      aria-label="input-wrapper"
    >
      <label className={style.label} aria-label={props.label}>
        {props.label}
      </label>
      <input
        type={"number"}
        className={style.input}
        aria-label={props.label}
        placeholder={props.placeholder}
        onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange(parseInt(e.target.value))}
        value={props.value}
      />
    </div>
  );
};
