import {
  authenticatedFetchDelete,
  authenticatedFetchGet,
  authenticatedFetchPost,
  authenticatedFetchPut,
  baseUrl
} from ".";
import { Scene } from "../redux/types";

const getScenes = (storyId: string) => {
  return authenticatedFetchGet(`${baseUrl}/scenes/${storyId}`);
};

const addScene = (storyId: string, sceneIndex: number) => {
  return authenticatedFetchPost(`${baseUrl}/scenes`, {
    storyId,
    sceneIndex
  });
};

const updateScene = (storyId: string, scene: Scene) => {
  return authenticatedFetchPut(`${baseUrl}/scenes`, {
    scene,
    storyId
  });
};

const deleteScene = (storyId: string, scene: Scene) => {
  return authenticatedFetchDelete(`${baseUrl}/scenes`, {
    storyId,
    scene
  });
};

export const sceneApi = {
  getScenes,
  addScene,
  updateScene,
  deleteScene
};
