import React from "react";
import classNames from "classnames";
import style from "./button.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export enum BUTTON_SIZE {
  NORMAL = "NORMAL",
  BIG = "BIG"
}

interface ButtonProps {
  onClick?: Function;
  label?: string;
  icon?: IconProp;
  round?: boolean;
  roundedCorners?: boolean;
  className?: string;
  transparent?: boolean;
  stretch?: boolean;
  size?: BUTTON_SIZE;
  disabled?: boolean;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  className,
  onClick,
  label,
  icon,
  round,
  roundedCorners,
  transparent,
  stretch,
  disabled = false,
  size = BUTTON_SIZE.NORMAL
}) => {
  return (
    <button
      onClick={() => onClick && !disabled && onClick()}
      className={classNames(
        className,
        style.Button,
        { [style.Button__round]: round },
        { [style.Button__roundedCorners]: roundedCorners },
        { [style.Button__transparent]: transparent },
        { [style.Button__stretch]: stretch },
        { [style.Button__big]: size === BUTTON_SIZE.BIG }
      )}
      disabled={disabled}
    >
      {icon && (
        <div className={classNames(style.icon, { [style.icon__padding]: !round && !!label })}>
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      {label && label}
    </button>
  );
};
