import React, { FunctionComponent } from "react";
import style from "./modal.module.scss";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IModal {
  show: boolean;
  title: string;
  onExit: () => void;
}

export const Modal: FunctionComponent<IModal> = (props) => {
  const handleClose = () => {
    props.onExit();
  };

  return (
    <>
      {props.show && (
        <div className={style.Modal}>
          <div className={style.container}>
            <div className={style.header}>
              <div className={style.title}>{props.title}</div>
              <div className={style.closeIcon} onClick={handleClose}>
                <FontAwesomeIcon className={style.icon} icon={faTimes} />
              </div>
            </div>
            <div className={style.content}>{props.children}</div>
          </div>
        </div>
      )}
    </>
  );
};
