import React, { useState, useEffect, FunctionComponent, useMemo } from "react";
import style from "./login-screen.module.scss";
import { Button, BUTTON_SIZE, Input } from "../../controls";
import { ROUTES } from "../../../utils";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useDispatch } from "react-redux";
import { addMessageAsync } from "../../../redux/actions";
import { MessageType } from "../../../redux/types";

interface LoginFormProps {
  onLogin: (email: string, password: string) => void;
}

const LoginForm: FunctionComponent<LoginFormProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enterLogin, setEnterLogin] = useState(false);
  const [verified, setVerified] = useState(false);
  const dispatch = useDispatch();

  const isDevelopment = useMemo(() => process.env.NODE_ENV === "development", []);

  useEffect(() => {
    if (enterLogin) {
      handleLogin(email, password);
    }
  }, [enterLogin]);

  useEffect(() => {
    const listener = function (keyUpEvent: KeyboardEvent) {
      if (keyUpEvent.key === "Enter") {
        setEnterLogin(true);
      }
    };

    window.addEventListener("keyup", listener);

    return () => {
      window.removeEventListener("keyup", listener);
    };
  }, []);

  const handleLogin = (email: string, password: string) => {
    if (verified || isDevelopment) {
      if (!!email && !!password) {
        props.onLogin(email, password);
        setEnterLogin(false);
      } else {
        dispatch(
          addMessageAsync(MessageType.WARNING, "Please fill out your credentials", true, "Login or Password empty")
        );
      }
    } else {
      dispatch(
        addMessageAsync(
          MessageType.WARNING,
          "Please verify via the captcha shown to you",
          false,
          "Prove you're not a bot"
        )
      );
    }
  };

  return (
    <div className={style.LoginScreen}>
      <Input
        label="Email"
        placeholder="Enter your email"
        onChange={(value: string) => setEmail(value)}
        value={email}
        borderless={true}
      />
      <Input
        password={true}
        label="Password"
        borderless={true}
        placeholder="Enter your password"
        onChange={(value: string) => setPassword(value)}
        value={password}
      />
      <a className={style.forgotPassword} href={`${ROUTES.FORGOT_PASSWORD}/${email}`}>
        Forgot password?
      </a>
      {!isDevelopment && (
        <form name="captcha-verification" className={style.captcha}>
          <HCaptcha sitekey="59077fdc-6b8a-4b61-b6d4-2aebb47fe70c" onVerify={() => setVerified(true)} />
        </form>
      )}
      <Button
        stretch={false}
        roundedCorners={true}
        size={BUTTON_SIZE.BIG}
        label="Login"
        onClick={() => handleLogin(email, password)}
      />
    </div>
  );
};

export default LoginForm;
