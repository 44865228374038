import { ReduxAction, ReduxUserState } from "../types";
import { userActionTypes } from "../actions/actionTypes";
import { produce } from "immer";

const initialState: ReduxUserState = {
  token: undefined,
  isLoading: true
};

const userReducer = (state: ReduxUserState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case userActionTypes.SET_TOKEN:
      return produce(state, (draftState) => {
        draftState.token = action.payload.token;
      });
    case userActionTypes.DELETE_TOKEN:
      return produce(state, (draftState) => {
        delete draftState.token;
      });
    case userActionTypes.SET_TOKEN_LOADING:
      return produce(state, (draftState) => {
        draftState.isLoading = action.payload.isLoading;
      });
    default:
      return state;
  }
};

export default userReducer;
