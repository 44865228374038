import { MessageType } from "../types";
import { messageActionTypes } from "./actionTypes";
import { v4 } from "uuid";
import { ThunkDispatch } from "redux-thunk";

const MESSAGE_TIMEOUT = 5000;

const addMessage = (
  id: string,
  type: MessageType,
  text: string,
  autoClose: boolean,
  title?: string
) => ({
  type: messageActionTypes.ADD_MESSAGE,
  payload: {
    id,
    type,
    text,
    autoClose,
    title,
  },
});

export const removeMessage = (id: string) => ({
  type: messageActionTypes.REMOVE_MESSAGE,
  payload: {
    id,
  },
});

export const addMessageAsync = (
  type: MessageType,
  text: string,
  autoClose: boolean = true,
  title?: string
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const messageID = v4();
  dispatch(addMessage(messageID, type, text, autoClose, title));
  if (autoClose) {
    setTimeout(() => {
      dispatch(removeMessage(messageID));
    }, MESSAGE_TIMEOUT);
  }
};
