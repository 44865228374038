import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { MessageType, GlobalReduxState, Message } from "../../../redux/types";
import style from "./MessageDisplay.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { removeMessage } from "../../../redux/actions";

export const MessageDisplay = () => {
  const messages = useSelector((state: GlobalReduxState) => state.messages.messages);
  const dispatch = useDispatch();

  const closeMessage = (message: Message) => {
    dispatch(removeMessage(message.id));
  };

  return (
    <div className={style.MessageDisplay}>
      {messages &&
        messages.map((msg, idx) => (
          <div
            key={`message-${idx}`}
            className={classNames(
              style.toast,
              { [style.toast__success]: msg.type === MessageType.INFO },
              { [style.toast__warning]: msg.type === MessageType.WARNING },
              { [style.toast__error]: msg.type === MessageType.ERROR }
            )}
          >
            <div className={style.header}>
              <label className={style.title} title={msg.title}>
                {msg.title}
              </label>
              <span className={style.icon} onClick={() => closeMessage(msg)}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
            <label className={style.text} title={msg.text}>
              {msg.text}
            </label>
          </div>
        ))}
    </div>
  );
};
