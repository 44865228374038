import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import style from "./navigation.module.scss";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { deleteToken } from "../../../redux/actions";
import { ROUTES } from "../../../utils";

export const Navigation = () => {
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(deleteToken());
    history.push(ROUTES.LOGIN);
  };

  const goToSettings = () => {
    history.push(ROUTES.SETTINGS);
  };

  return (
    <div className={style.Navigation}>
      <label className={style.title} title="Draftical">
        Draftical
      </label>
      <div className={style.icons}>
        <div className={style.icon}>
          <div className={classNames(style.actions, { [style.actions__expanded]: expanded })}>
            <label className={style.label} onClick={() => goToSettings()}>
              Settings
            </label>
            <label className={style.label} onClick={() => logout()}>
              Logout
            </label>
          </div>
          <div className={style.innerIcon} onClick={() => setExpanded(!expanded)}>
            <FontAwesomeIcon icon={faUserCircle} />
          </div>
        </div>
      </div>
    </div>
  );
};
