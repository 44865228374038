import { ThunkDispatch } from "redux-thunk";
import { characterApi, ServerResponse } from "../../api";
import { Character, MessageType } from "../types";
import { characterActionTypes } from "./actionTypes";
import { addMessageAsync } from "./messageActions";
import { addCharacterToScene, removeCharacterFromScene } from "./sceneActions";

export const setCharacters = (characters: Character[]) => ({
  type: characterActionTypes.SET_CHARACTERS,
  payload: {
    characters
  }
});

export const clearCharacters = () => ({
  type: characterActionTypes.CLEAR_CHARACTERS
});

export const addCharacter = (character: Character) => ({
  type: characterActionTypes.ADD_CHARACTER,
  payload: {
    character
  }
});

export const updateCharacter = (character: Partial<Character>) => ({
  type: characterActionTypes.UPDATE_CHARACTER,
  payload: {
    character
  }
});

export const deleteCharacter = (character: Character) => ({
  type: characterActionTypes.DELETE_CHARACTER,
  payload: {
    character
  }
});

export const getCharactersAsync = (storyId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result: ServerResponse = await characterApi.getCharacters(storyId);

  if (result.statusOk) {
    dispatch(setCharacters(result.data));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, `Error while loading characters: ${result?.error}`));
  }
};

export const createCharacterAsync =
  (storyId: string, character: Partial<Character>) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await characterApi.createCharacter(storyId, character);

    if (result.statusOk) {
      dispatch(addCharacter(result.data));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while creating character: ${result?.error}`));
    }
  };

export const updateCharacterAsync =
  (storyId: string, character: Partial<Character>) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await characterApi.updateCharacter(storyId, character);

    if (result.statusOk) {
      dispatch(updateCharacter(character));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while updating character: ${result?.error}`));
    }
  };

export const deleteCharacterAsync = (character: Character) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result: ServerResponse = await characterApi.deleteCharacter(character);

  if (result.statusOk) {
    dispatch(deleteCharacter(character));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, `Error while deleting a character: ${result?.error}`));
  }
};

export const addCharacterToSceneAsync =
  (sceneId: string, characterId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await characterApi.addCharacterToScene(sceneId, characterId);

    if (result.statusOk) {
      dispatch(addCharacterToScene(sceneId, characterId));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while adding character to scene: ${result?.error}`));
    }
  };

export const removeCharacterFromSceneAsync =
  (sceneId: string, characterId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await characterApi.removeCharacterFromScene(sceneId, characterId);

    if (result.statusOk) {
      dispatch(removeCharacterFromScene(sceneId, characterId));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while adding character to scene: ${result?.error}`));
    }
  };
