import {
  baseUrl,
  authenticatedFetchGet,
  authenticatedFetchPost,
  authenticatedFetchPut,
  authenticatedFetchDelete
} from ".";
import { Item } from "../redux/types";

const getItems = (storyId: string) => {
  return authenticatedFetchGet(`${baseUrl}/items/${storyId}`);
};

const createItem = (storyId: string, item: Partial<Item>) => {
  return authenticatedFetchPost(`${baseUrl}/items`, { storyId, item });
};

const updateItem = (storyId: string, item: Partial<Item>) => {
  return authenticatedFetchPut(`${baseUrl}/items`, { storyId, item });
};

const deleteItem = (item: Item) => {
  return authenticatedFetchDelete(`${baseUrl}/items/${item.id}`);
};

const addItemToScene = (sceneId: string, itemId: string) => {
  return authenticatedFetchPost(`${baseUrl}/items/${itemId}/${sceneId}`);
};

const removeItemFromScene = (sceneId: string, itemId: string) => {
  return authenticatedFetchDelete(`${baseUrl}/items/${itemId}/${sceneId}`);
};

export const itemApi = {
  getItems,
  createItem,
  updateItem,
  deleteItem,
  addItemToScene,
  removeItemFromScene
};
