import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsAsync } from "../../../redux/actions/locationActions";
import { LocationSelector } from "../../../redux/selectors";
import { Location } from "../../../redux/types";
import style from "./location-list.module.scss";

interface LocationListProps {
  onUpdateLocation?: (location: Location) => void;
  onDeleteLocation?: (location: Location) => void;
  onSelectLocation?: (location: Location) => void;
  selection?: boolean;
  storyId: string;
  filterFunc?: (location: Location) => boolean;
}

export const LocationList: FunctionComponent<LocationListProps> = ({
  onUpdateLocation,
  onDeleteLocation,
  onSelectLocation,
  selection = false,
  storyId,
  filterFunc = (location: Location) => true
}) => {
  const dispatch = useDispatch();
  const locations: Location[] = useSelector(LocationSelector);

  const [showDetails, setShowDetails] = useState<boolean[]>([]);

  useEffect(() => {
    if (storyId) {
      dispatch(getLocationsAsync(storyId));
    }
  }, [storyId]);

  useEffect(() => {
    if (locations) {
      setShowDetails(new Array(locations.length).fill(false));
    }
  }, [locations]);

  const handleShowDetail = (idx: number) => {
    setShowDetails(
      showDetails.reduce((result: boolean[], item: boolean, index: number) => {
        if (index === idx) {
          result.push(!item);
        } else {
          result.push(item);
        }

        return result;
      }, [])
    );
  };

  const handleTitleClick = (location: Location, idx: number) => {
    if (selection && onSelectLocation) {
      onSelectLocation(location);
    } else {
      handleShowDetail(idx);
    }
  };

  return (
    <div className={style.LocationList}>
      {locations &&
        locations.length > 0 &&
        locations.filter(filterFunc).map((location: Location, idx: number) => (
          <div
            className={classNames(style.location, { [style.location__small]: selection })}
            key={`location-list-entry-${location.id}`}
          >
            <div className={style.title} onClick={() => handleTitleClick(location, idx)}>
              <span className={style.name}>{location.name}</span>
              <div className={style.buttons}>
                {!selection && (
                  <div
                    className={style.button}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onUpdateLocation && onUpdateLocation(location);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </div>
                )}
                {!selection && (
                  <div
                    className={style.button}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onDeleteLocation && onDeleteLocation(location);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                )}
              </div>
            </div>
            <div className={classNames(style.details, { [style.details__show]: showDetails[idx] })}>
              <p>Description: {location.description}</p>
            </div>
          </div>
        ))}
      {locations.filter(filterFunc) && locations.filter(filterFunc).length === 0 && (
        <h3>{selection ? "No locations available" : "No locations created yet"}</h3>
      )}
    </div>
  );
};
