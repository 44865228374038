import { faArrowLeft, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ItemForm } from "../../../forms";
import { ItemList } from "../../../pages/item-dashboard/item-list/item-list";
import { addItemToSceneAsync, createItemAsync, removeItemFromSceneAsync } from "../../../redux/actions/itemActions";
import { ItemSelector } from "../../../redux/selectors";
import { Item, Scene } from "../../../redux/types";
import { Button, BUTTON_SIZE, Modal } from "../../controls";
import style from "./item-container.module.scss";

interface ItemContainerProps {
  scene: Scene;
  storyId: string;
}

export const ItemContainer: FunctionComponent<ItemContainerProps> = ({ scene, storyId }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showNewCharModal, setShowNewCharModal] = useState(false);
  const dispatch = useDispatch();

  const items = useSelector(ItemSelector);

  const sceneItems = items.filter((char) => {
    return scene.items.indexOf(char.id) > -1;
  });

  const handleAddItem = (item: Item) => {
    dispatch(addItemToSceneAsync(scene.id, item.id));
    setShowSearch(false);
  };

  const handleRemoveItem = (item: Item) => {
    dispatch(removeItemFromSceneAsync(scene.id, item.id));
  };

  const createNewItem = (item: Partial<Item>) => {
    dispatch(createItemAsync(storyId, item));
    setShowNewCharModal(false);
  };

  return (
    <div className={style.ItemContainer}>
      {!showSearch ? (
        <div className={style.addedList}>
          <Button
            roundedCorners={true}
            label="Add Item to List"
            onClick={() => setShowSearch(true)}
            size={BUTTON_SIZE.BIG}
            icon={faPlus}
            className={style.addButton}
          />
          <div className={style.list}>
            {sceneItems &&
              sceneItems.map((char) => (
                <div className={style.item} key={`scene-item-${char.id}`}>
                  <span>{char.name}</span>
                  <label onClick={() => handleRemoveItem(char)} className={style.remove} title="Remove Item from scene">
                    <FontAwesomeIcon icon={faTimes} />
                  </label>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className={style.searchList}>
          <div className={style.buttons}>
            <Button
              roundedCorners={true}
              className={style.button}
              size={BUTTON_SIZE.BIG}
              icon={faArrowLeft}
              label="Zurück"
              onClick={() => setShowSearch(false)}
            />
            <Button
              roundedCorners={true}
              className={style.button}
              size={BUTTON_SIZE.BIG}
              icon={faPlus}
              stretch={true}
              label="Create new Item"
              onClick={() => setShowNewCharModal(true)}
            />
          </div>
          <ItemList
            storyId={storyId}
            selection={true}
            filterFunc={(item) => scene.items.indexOf(item.id) === -1}
            onSelectItem={(item: Item) => handleAddItem(item)}
          />
          <Modal title="Create new item" show={showNewCharModal} onExit={() => setShowNewCharModal(false)}>
            <ItemForm onSave={(item: Partial<Item>) => createNewItem(item)} />
          </Modal>
        </div>
      )}
    </div>
  );
};
