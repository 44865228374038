import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCharactersAsync } from "../../../redux/actions/characterActions";
import { CharacterSelector } from "../../../redux/selectors";
import { Character } from "../../../redux/types";
import style from "./character-list.module.scss";

interface CharacterListProps {
  onUpdateCharacter?: (character: Character) => void;
  onDeleteCharacter?: (character: Character) => void;
  onSelectCharacter?: (character: Character) => void;
  selection?: boolean;
  storyId: string;
  filterFunc?: (character: Character) => boolean;
}

export const CharacterList: FunctionComponent<CharacterListProps> = ({
  onUpdateCharacter,
  onDeleteCharacter,
  onSelectCharacter,
  selection = false,
  storyId,
  filterFunc = (character: Character) => true
}) => {
  const dispatch = useDispatch();
  const characters: Character[] = useSelector(CharacterSelector);

  const [showDetails, setShowDetails] = useState<boolean[]>([]);

  useEffect(() => {
    if (storyId) {
      dispatch(getCharactersAsync(storyId));
    }
  }, [storyId]);

  useEffect(() => {
    if (characters) {
      setShowDetails(new Array(characters.length).fill(false));
    }
  }, [characters]);

  const handleShowDetail = (idx: number) => {
    setShowDetails(
      showDetails.reduce((result: boolean[], item: boolean, index: number) => {
        if (index === idx) {
          result.push(!item);
        } else {
          result.push(item);
        }

        return result;
      }, [])
    );
  };

  const handleTitleClick = (character: Character, idx: number) => {
    if (selection && onSelectCharacter) {
      onSelectCharacter(character);
    } else {
      handleShowDetail(idx);
    }
  };

  return (
    <div className={style.CharacterList}>
      {characters &&
        characters.length > 0 &&
        characters.filter(filterFunc).map((character: Character, idx: number) => (
          <div
            className={classNames(style.character, { [style.character__small]: selection })}
            key={`character-list-entry-${character.id}`}
          >
            <div className={style.title} onClick={() => handleTitleClick(character, idx)}>
              <span className={style.name}>
                {character.firstName} {character.lastName}
              </span>
              <div className={style.buttons}>
                {!selection && (
                  <div
                    className={style.button}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onUpdateCharacter && onUpdateCharacter(character);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </div>
                )}
                {!selection && (
                  <div
                    className={style.button}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onDeleteCharacter && onDeleteCharacter(character);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                )}
              </div>
            </div>
            <div className={classNames(style.details, { [style.details__show]: showDetails[idx] })}>
              <label>Age: {character.age}</label>
              <p>Description: {character.description}</p>
            </div>
          </div>
        ))}
      {characters.filter(filterFunc) && characters.filter(filterFunc).length === 0 && (
        <h3>{selection ? "No characters available" : "No characters created yet"}</h3>
      )}
    </div>
  );
};
