import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Button, BUTTON_SIZE, Modal } from "../../components/controls";
import { CharacterForm } from "../../forms";
import { createCharacterAsync, deleteCharacterAsync, updateCharacterAsync } from "../../redux/actions/characterActions";
import { Character } from "../../redux/types";
import style from "./character-dashboard.module.scss";
import { CharacterList } from "./character-list/character-list";

interface CharacterDashboardRoutingParams {
  storyId: string;
}

export const CharacterDashboard = () => {
  const dispatch = useDispatch();
  const { storyId } = useParams<CharacterDashboardRoutingParams>();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [characterToUpdate, setCharacterToUpdate] = useState<Character | undefined>(undefined);

  const createNewCharacter = (character: Partial<Character>) => {
    dispatch(createCharacterAsync(storyId, character));
    setShowModal(false);
  };

  const updateCharacter = (character: Partial<Character>) => {
    dispatch(updateCharacterAsync(storyId, { id: characterToUpdate?.id, ...character }));
    setCharacterToUpdate(undefined);
  };

  const handleUpdateCharacter = (character: Character) => {
    setCharacterToUpdate(character);
  };

  const handleDeleteCharacter = (character: Character) => {
    const confirm = window.confirm("Do you want to permanently delete this character?");
    if (!!confirm) {
      dispatch(deleteCharacterAsync(character));
    }
  };

  return (
    <div className={style.CharacterDashboard}>
      <Button
        icon={faArrowLeft}
        label="Back to Story"
        size={BUTTON_SIZE.BIG}
        onClick={() => history.push(`/story/${storyId}`)}
      />
      <h2>Characters</h2>
      <Button size={BUTTON_SIZE.BIG} icon={faPlus} label="New Character" onClick={() => setShowModal(true)} />
      <CharacterList
        storyId={storyId}
        onUpdateCharacter={(character: Character) => handleUpdateCharacter(character)}
        onDeleteCharacter={(character: Character) => handleDeleteCharacter(character)}
      />
      <Modal title="Create new character" show={showModal} onExit={() => setShowModal(false)}>
        <CharacterForm onSave={(character: Partial<Character>) => createNewCharacter(character)} />
      </Modal>
      <Modal title="Update character" show={!!characterToUpdate} onExit={() => setCharacterToUpdate(undefined)}>
        <CharacterForm
          character={characterToUpdate}
          onSave={(character: Partial<Character>) => updateCharacter(character)}
        />
      </Modal>
    </div>
  );
};
