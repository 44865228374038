import React, { FunctionComponent, ReactElement } from "react";
import style from "./tab.module.scss";
import classNames from "classnames";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface InnerTabProps {
  active?: boolean;
  onClick: () => void;
  label: string;
  icon: IconDefinition;
}

interface TabProps {
  label?: string;
  title?: string;
  onChange?: (value: string) => void;
  icon: IconDefinition;
  children?: any;
}

export const Tab: FunctionComponent<TabProps> = (props: TabProps) => {
  return <>{props.children}</>;
};

export const InnerTab: FunctionComponent<InnerTabProps> = (props) => {
  return (
    <div className={style.Tab} onClick={props.onClick}>
      {props.icon && (
        <FontAwesomeIcon
          className={classNames(style.icon, {
            [style.icon__active]: props.active,
          })}
          icon={props.icon}
        />
      )}
    </div>
  );
};
