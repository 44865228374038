import { ThunkDispatch } from "redux-thunk";
import { storyApi } from "../../api";
import { GlobalReduxState, MessageType, Story } from "../types";
import { storyActionTypes } from "./actionTypes";
import { addMessageAsync } from "./messageActions";

export const setStories = (stories: Story[]) => ({
  type: storyActionTypes.SET_STORIES,
  payload: {
    stories
  }
});

export const getStoriesAsync = () => async (dispatch: ThunkDispatch<any, any, any>, state: GlobalReduxState) => {
  const result = await storyApi.getStories();

  if (result.statusOk) {
    dispatch(setStories(result.data));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, result.error));
  }
};

export const createNewStoryAsync = (name: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result = await storyApi.createStory(name);

  if (result.statusOk) {
    dispatch(getStoriesAsync());
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, result.error));
  }
};

export const deleteStoryAsync = (storyId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result = await storyApi.deleteStory(storyId);

  if (result.statusOk) {
    dispatch(getStoriesAsync());
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, result.error));
  }
};
