export interface ReduxMessageState {
  messages: Message[];
}

export interface Message {
  id: string;
  type: MessageType;
  title?: string;
  text: string;
  autoClose?: boolean;
}

export enum MessageType {
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
}
