import produce from "immer";
import { characterActionTypes } from "../actions/actionTypes";
import { ReduxAction, ReduxCharacterState } from "../types";

const initialCharacterState: ReduxCharacterState = {
  characters: []
};

export default function (state: ReduxCharacterState = initialCharacterState, action: ReduxAction) {
  switch (action.type) {
    case characterActionTypes.SET_CHARACTERS:
      return produce(state, (draftState) => {
        draftState.characters = action.payload.characters;
      });

    case characterActionTypes.CLEAR_CHARACTERS:
      return produce(state, (draftState) => {
        draftState.characters = [];
      });
    case characterActionTypes.ADD_CHARACTER:
      return produce(state, (draftState) => {
        draftState.characters.push(action.payload.character);
      });
    case characterActionTypes.DELETE_CHARACTER:
      return produce(state, (draftState) => {
        draftState.characters = draftState.characters.filter((char) => char.id !== action.payload.character.id);
      });
    case characterActionTypes.UPDATE_CHARACTER:
      return produce(state, (draftState) => {
        draftState.characters = draftState.characters.map((char) => {
          if (char.id === action.payload.character.id) {
            return action.payload.character;
          }
          return char;
        });
      });
    default:
      return state;
  }
}
