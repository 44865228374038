import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Button, BUTTON_SIZE, Modal } from "../../components/controls";
import { LocationForm } from "../../forms";
import { createLocationAsync, deleteLocationAsync, updateLocationAsync } from "../../redux/actions/locationActions";
import { Location } from "../../redux/types";
import style from "./location-dashboard.module.scss";
import { LocationList } from "./location-list/location-list";

interface LocationDashboardRoutingParams {
  storyId: string;
}

export const LocationDashboard = () => {
  const dispatch = useDispatch();
  const { storyId } = useParams<LocationDashboardRoutingParams>();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [locationToUpdate, setLocationToUpdate] = useState<Location | undefined>(undefined);

  const createNewLocation = (location: Partial<Location>) => {
    dispatch(createLocationAsync(storyId, location));
    setShowModal(false);
  };

  const updateLocation = (location: Partial<Location>) => {
    dispatch(updateLocationAsync(storyId, { id: locationToUpdate?.id, ...location }));
    setLocationToUpdate(undefined);
  };

  const handleUpdateLocation = (location: Location) => {
    setLocationToUpdate(location);
  };

  const handleDeleteLocation = (location: Location) => {
    const confirm = window.confirm("Do you want to permanently delete this location?");
    if (!!confirm) {
      dispatch(deleteLocationAsync(location));
    }
  };

  return (
    <div className={style.LocationDashboard}>
      <Button
        icon={faArrowLeft}
        label="Back to Story"
        size={BUTTON_SIZE.BIG}
        onClick={() => history.push(`/story/${storyId}`)}
      />
      <h2>Locations</h2>
      <Button size={BUTTON_SIZE.BIG} icon={faPlus} label="New Location" onClick={() => setShowModal(true)} />
      <LocationList
        storyId={storyId}
        onUpdateLocation={(location: Location) => handleUpdateLocation(location)}
        onDeleteLocation={(location: Location) => handleDeleteLocation(location)}
      />
      <Modal title="Create new location" show={showModal} onExit={() => setShowModal(false)}>
        <LocationForm onSave={(location: Partial<Location>) => createNewLocation(location)} />
      </Modal>
      <Modal title="Update location" show={!!locationToUpdate} onExit={() => setLocationToUpdate(undefined)}>
        <LocationForm location={locationToUpdate} onSave={(location: Partial<Location>) => updateLocation(location)} />
      </Modal>
    </div>
  );
};
