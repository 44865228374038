export const sceneActionTypes = {
  SET_SCENES: "SET_SCENES",
  UPDATE_SCENE: "UPDATE_SCENE",
  DELETE_SCENE: "DELETE_SCENE",
  MOVE_SCENE: "MOVE_SCENE",
  ADD_CHARACTER_TO_SCENE: "ADD_CHARACTER_TO_SCENE",
  REMOVE_CHARACTER_FROM_SCENE: "REMOVE_CHARACTER_FROM_SCENE",
  ADD_LOCATION_TO_SCENE: "ADD_LOCATION_TO_SCENE",
  REMOVE_LOCATION_FROM_SCENE: "REMOVE_LOCATION_FROM_SCENE",
  ADD_ITEM_TO_SCENE: "ADD_ITEM_TO_SCENE",
  REMOVE_ITEM_FROM_SCENE: "REMOVE_ITEM_FROM_SCENE"
};

export const navigationActionTypes = {
  TOGGLE_SHRINK_HEADSPACE: "TOGGLE_SHRINK_HEADSPACE"
};

export const userActionTypes = {
  SET_TOKEN: "SET_TOKEN",
  LOAD_TOKEN: "LOAD_TOKEN",
  DELETE_TOKEN: "DELETE_TOKEN",
  SET_TOKEN_LOADING: "SET_TOKEN_LOADING"
};

export const messageActionTypes = {
  ADD_MESSAGE: "ADD_MESSAGE",
  REMOVE_MESSAGE: "REMOVE_MESSAGE",
  CLEAR_MESSAGES: "CLEAR_MESSAGES"
};

export const storyActionTypes = {
  SET_STORIES: "SET_STORIES",
  DELETE_STORY: "DELETE_STORY",
  UPDATE_STORY: "UPDATE_STORY",
  CREATE_STORY: "CREATE_STORY"
};

export const characterActionTypes = {
  SET_CHARACTERS: "SET_CHARACTERS",
  DELETE_CHARACTER: "DELETE_CHARACTER",
  UPDATE_CHARACTER: "UPDATE_CHARACTER",
  CREATE_CHARACTER: "CREATE_CHARACTER",
  CLEAR_CHARACTERS: "CLEAR_CHARACTERS",
  ADD_CHARACTER: "ADD_CHARACTER"
};

export const locationActionTypes = {
  SET_LOCATIONS: "SET_LOCATIONS",
  DELETE_LOCATION: "DELETE_LOCATION",
  UPDATE_LOCATION: "UPDATE_LOCATION",
  CREATE_LOCATION: "CREATE_LOCATION",
  CLEAR_LOCATIONS: "CLEAR_LOCATIONS",
  ADD_LOCATION: "ADD_LOCATION"
};

export const itemActionTypes = {
  SET_ITEMS: "SET_ITEMS",
  DELETE_ITEM: "DELETE_ITEM",
  UPDATE_ITEM: "UPDATE_ITEM",
  CREATE_ITEM: "CREATE_ITEM",
  CLEAR_ITEMS: "CLEAR_ITEMS",
  ADD_ITEM: "ADD_ITEM"
};
