import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemsAsync } from "../../../redux/actions/itemActions";
import { ItemSelector } from "../../../redux/selectors";
import { Item } from "../../../redux/types";
import style from "./item-list.module.scss";

interface ItemListProps {
  onUpdateItem?: (item: Item) => void;
  onDeleteItem?: (item: Item) => void;
  onSelectItem?: (item: Item) => void;
  selection?: boolean;
  storyId: string;
  filterFunc?: (item: Item) => boolean;
}

export const ItemList: FunctionComponent<ItemListProps> = ({
  onUpdateItem,
  onDeleteItem,
  onSelectItem,
  selection = false,
  storyId,
  filterFunc = (item: Item) => true
}) => {
  const dispatch = useDispatch();
  const items: Item[] = useSelector(ItemSelector);

  const [showDetails, setShowDetails] = useState<boolean[]>([]);

  useEffect(() => {
    if (storyId) {
      dispatch(getItemsAsync(storyId));
    }
  }, [storyId]);

  useEffect(() => {
    if (items) {
      setShowDetails(new Array(items.length).fill(false));
    }
  }, [items]);

  const handleShowDetail = (idx: number) => {
    setShowDetails(
      showDetails.reduce((result: boolean[], item: boolean, index: number) => {
        if (index === idx) {
          result.push(!item);
        } else {
          result.push(item);
        }

        return result;
      }, [])
    );
  };

  const handleTitleClick = (item: Item, idx: number) => {
    if (selection && onSelectItem) {
      onSelectItem(item);
    } else {
      handleShowDetail(idx);
    }
  };

  return (
    <div className={style.ItemList}>
      {items &&
        items.length > 0 &&
        items.filter(filterFunc).map((item: Item, idx: number) => (
          <div
            className={classNames(style.item, { [style.item__small]: selection })}
            key={`item-list-entry-${item.id}`}
          >
            <div className={style.title} onClick={() => handleTitleClick(item, idx)}>
              <span className={style.name}>{item.name}</span>
              <div className={style.buttons}>
                {!selection && (
                  <div
                    className={style.button}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onUpdateItem && onUpdateItem(item);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </div>
                )}
                {!selection && (
                  <div
                    className={style.button}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onDeleteItem && onDeleteItem(item);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                )}
              </div>
            </div>
            <div className={classNames(style.details, { [style.details__show]: showDetails[idx] })}>
              <p>Description: {item.description}</p>
            </div>
          </div>
        ))}
      {items.filter(filterFunc) && items.filter(filterFunc).length === 0 && (
        <h3>{selection ? "No items available" : "No items created yet"}</h3>
      )}
    </div>
  );
};
