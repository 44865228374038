import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import { Playground } from "./pages/playground/playground";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import store from "./redux/store";
import { ROUTES } from "./utils";
import { MessageDisplay } from "./components/controls";
import PasswordReset from "./pages/password-reset/password-reset";
import ForgotPassword from "./pages/forgot-password/forgot-password";

const devMode = process.env.NODE_ENV === "development";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        {devMode && (
          <Route exact path={ROUTES.PLAYGROUND}>
            <Playground />
          </Route>
        )}
        <Route path={[`${ROUTES.FORGOT_PASSWORD}/:email`, ROUTES.FORGOT_PASSWORD]}>
          <ForgotPassword />
        </Route>
        <Route exact path={`${ROUTES.PASSWORD_RESET}/:passwordID`}>
          <PasswordReset />
        </Route>
        <Route path={ROUTES.HOME}>
          <App />
        </Route>
      </Switch>
      <MessageDisplay />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
