import { authenticatedFetchDelete, authenticatedFetchGet, authenticatedFetchPost, baseUrl } from ".";

const getStories = () => {
  return authenticatedFetchGet(`${baseUrl}/stories`);
};

const createStory = (name: string) => {
  return authenticatedFetchPost(`${baseUrl}/stories`, {
    story: { name }
  });
};

const deleteStory = (storyId: string) => {
  return authenticatedFetchDelete(`${baseUrl}/stories/${storyId}`);
};

export const storyApi = {
  getStories,
  createStory,
  deleteStory
};
