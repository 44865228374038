import React, { FunctionComponent, ReactElement, useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { InnerTab } from "./tab";
import style from "./tab-container.module.scss";
import classNames from "classnames";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FunctionTab } from "../function-tab/function-tab";

export interface TabFunction {
  icon: IconDefinition;
  label?: string;
  onClick?: () => void;
  handleProps?: DraggableProvidedDragHandleProps;
}

interface TabContainerProps {
  children?: ReactElement[];
  className?: any;
  tabFunctions?: TabFunction[];
}

export const TabContainer: FunctionComponent<TabContainerProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const onTabClick = (index: number) => {
    setSelectedTab(index);
  };

  const renderLabel = () => {
    return (
      props?.children &&
      (props.children?.[selectedTab].props?.title !== undefined ? (
        <input
          className={style.title}
          value={props.children?.[selectedTab].props?.title}
          onChange={(event) => props.children?.[selectedTab].props?.onChange(event.target.value)}
        />
      ) : (
        <span className={style.label}>{props.children?.[selectedTab].props?.label}</span>
      ))
    );
  };

  const renderTabs = () => {
    return props?.children?.map((child: ReactElement, idx) => (
      <InnerTab
        active={selectedTab === idx}
        onClick={() => onTabClick(idx)}
        key={`tab-${idx}`}
        label={child?.props?.label}
        icon={child?.props?.icon}
      />
    ));
  };

  const renderTabFunctions = () => {
    return props?.tabFunctions?.map((tabFunction: TabFunction, idx: number) => (
      <FunctionTab key={`function-tab-${idx}`} {...tabFunction} />
    ));
  };

  return (
    <div className={classNames(style.TabContainer, props.className)}>
      <div className={style.tabHeader}>
        {renderLabel()}
        {renderTabs()}
        <div className={style.separator} />
        {renderTabFunctions()}
      </div>
      <div className={style.tabContent}>
        {props?.children?.map((child, idx) => {
          return selectedTab === idx ? child.props.children : undefined;
        })}
      </div>
    </div>
  );
};
