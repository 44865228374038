import React, { FunctionComponent, useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, BUTTON_SIZE, Input, NumberInput } from "../../components/controls";
import { Location } from "../../redux/types";
import style from "./location-form.module.scss";

export interface LocationFormProps {
  onSave: (location: Partial<Location>) => void;
  location?: Location;
}

export const LocationForm: FunctionComponent<LocationFormProps> = ({ onSave, location }) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (location) {
      setName(location.name);
      setDescription(location.description);
    }
  }, [location]);

  return (
    <div className={style.LocationForm}>
      <div className={style.values}>
        <Input label="Name" value={name} placeholder="Type name here" onChange={(value: string) => setName(value)} />
        <label>Description</label>
        <TextareaAutosize
          className={style.content}
          value={description}
          minRows={5}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
        />
      </div>

      <div className={style.footer}>
        <Button
          label="Save"
          stretch={true}
          size={BUTTON_SIZE.BIG}
          onClick={() =>
            onSave({
              name,
              description
            })
          }
        />
      </div>
    </div>
  );
};
