import { ReduxNavigationState } from "./navigationTypes";
import { Action } from "redux";
import { ReduxUserState } from "./userTypes";
import { ReduxMessageState } from "./messageTypes";
import { ReduxStoryState } from "./storyTypes";
import { ReduxSceneState } from "./sceneTypes";
import { ReduxCharacterState } from "./characterTypes";
import { ReduxLocationState } from "./locationTypes";
import { ReduxItemState } from "./itemTypes";

export * from "./navigationTypes";
export * from "./userTypes";
export * from "./messageTypes";
export * from "./storyTypes";
export * from "./sceneTypes";
export * from "./characterTypes";
export * from "./locationTypes";
export * from "./itemTypes";

export interface GlobalReduxState {
  navigation: ReduxNavigationState;
  user: ReduxUserState;
  messages: ReduxMessageState;
  stories: ReduxStoryState;
  scenes: ReduxSceneState;
  characters: ReduxCharacterState;
  locations: ReduxLocationState;
  items: ReduxItemState;
}

export interface ReduxAction extends Action {
  payload: any;
}
