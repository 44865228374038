import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import style from "./scene-dashboard.module.scss";
import classNames from "classnames";
import { Scene } from "../../components/building-blocks/scene/scene";
import { Button, BUTTON_SIZE } from "../../components/controls";
import { GlobalReduxState, Scene as SceneType } from "../../redux/types";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { ScenesSelector } from "../../redux/selectors";
import { useHistory, useParams } from "react-router";
import { addSceneAsync, getScenesAsync, moveSceneAsync, toggleShrinkHeadspace } from "../../redux/actions";
import { faArrowLeft, faDiceD6, faList, faMapMarkerAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../../utils";
import { getCharactersAsync } from "../../redux/actions/characterActions";
import { getLocationsAsync } from "../../redux/actions/locationActions";
import { getItemsAsync } from "../../redux/actions/itemActions";

interface SceneDashboardRoutingParams {
  storyId: string;
}

export const SceneDashboard: FunctionComponent = () => {
  const shrinkHeadspace = useSelector((state: GlobalReduxState) => state.navigation.shrinkHeadspace);
  const dispatch = useDispatch();
  const { storyId } = useParams<SceneDashboardRoutingParams>();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);

  const scenes = useSelector(ScenesSelector);

  useEffect(() => {
    if (!!storyId) {
      dispatch(getScenesAsync(storyId));
      dispatch(getCharactersAsync(storyId));
      dispatch(getLocationsAsync(storyId));
      dispatch(getItemsAsync(storyId));
    }
  }, [storyId, dispatch]);

  const onDragEnd = useCallback((result: DropResult) => {
    dispatch(toggleShrinkHeadspace(false));
    const { destination, source } = result;
    if (destination && source.index !== destination.index) {
      dispatch(moveSceneAsync(storyId, parseInt(result.draggableId), destination.index));
    }
  }, []);

  const sortScenes = useCallback((sceneA: SceneType, sceneB: SceneType) => {
    if (sceneA.sceneIndex < sceneB.sceneIndex) {
      return -1;
    } else {
      return 1;
    }
  }, []);

  return (
    <div className={style.SceneDashboard}>
      <div className={style.navigation}>
        <Button
          size={BUTTON_SIZE.BIG}
          label="Back to Stories"
          icon={faArrowLeft}
          onClick={() => history.push(ROUTES.DASHBOARD)}
        />
      </div>
      <div className={style.listButtons}>
        <label>
          <Button className={style.shadowButton} round={true} icon={faList} onClick={() => setExpanded(!expanded)} />
          <span>Lists</span>
        </label>
        <div className={classNames(style.buttons, { [style.buttons__expanded]: expanded })}>
          <label className={style.button} title="Characters">
            <Button
              className={style.shadowButton}
              round={true}
              icon={faUserCircle}
              onClick={() => history.push(`/story/${storyId}/characters`)}
            />
            <span>Characters</span>
          </label>
          <label className={style.button} title="Locations">
            <Button
              className={style.shadowButton}
              round={true}
              icon={faMapMarkerAlt}
              onClick={() => history.push(`/story/${storyId}/locations`)}
            />
            <span>Locations</span>
          </label>
          <label className={style.button} title="Items">
            <Button
              className={style.shadowButton}
              round={true}
              icon={faDiceD6}
              onClick={() => history.push(`/story/${storyId}/items`)}
            />
            <span>Items</span>
          </label>
        </div>
      </div>
      <div
        className={classNames(style.emptySpace, {
          [style.emptySpace__shrink]: shrinkHeadspace
        })}
      ></div>
      <div className={style.draggableSceneContainer}>
        <DragDropContext onDragEnd={(result: DropResult) => onDragEnd(result)}>
          <Droppable droppableId="scenedashboard-droppable">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {scenes &&
                  scenes.length > 0 &&
                  [...scenes] // Apparently Droppable ragequits without copying this array
                    .sort(sortScenes)
                    .map((scene, idx) => (
                      <Scene
                        storyId={storyId}
                        onAddScene={(scIndex: number) => dispatch(addSceneAsync(storyId, scIndex))}
                        scene={scene}
                        draggableIndex={idx}
                        key={`scene-${idx}`}
                        hideButtons={snapshot.isDraggingOver}
                      />
                    ))}
                {scenes && scenes.length === 0 && (
                  <Button
                    size={BUTTON_SIZE.BIG}
                    label="Add your first scene"
                    onClick={() => dispatch(addSceneAsync(storyId, 0))}
                  />
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
