import { navigationActionTypes } from "../actions/actionTypes";
import { ReduxNavigationState } from "../types/navigationTypes";
import { ReduxAction } from "../types";

const initialStoryState: ReduxNavigationState = {
  shrinkHeadspace: false
};

export default function(
  state: ReduxNavigationState = initialStoryState,
  action: ReduxAction
) {
  switch (action.type) {
    case navigationActionTypes.TOGGLE_SHRINK_HEADSPACE:
      return {
        ...state,
        shrinkHeadspace: action.payload.shrink
      };
    default:
      return state;
  }
}
