import {
  baseUrl,
  authenticatedFetchGet,
  authenticatedFetchPost,
  authenticatedFetchPut,
  authenticatedFetchDelete
} from ".";
import { Location } from "../redux/types";

const getLocations = (storyId: string) => {
  return authenticatedFetchGet(`${baseUrl}/locations/${storyId}`);
};

const createLocation = (storyId: string, location: Partial<Location>) => {
  return authenticatedFetchPost(`${baseUrl}/locations`, { storyId, location });
};

const updateLocation = (storyId: string, location: Partial<Location>) => {
  return authenticatedFetchPut(`${baseUrl}/locations`, { storyId, location });
};

const deleteLocation = (location: Location) => {
  return authenticatedFetchDelete(`${baseUrl}/locations/${location.id}`);
};

const addLocationToScene = (sceneId: string, locationId: string) => {
  return authenticatedFetchPost(`${baseUrl}/locations/${locationId}/${sceneId}`);
};

const removeLocationFromScene = (sceneId: string, locationId: string) => {
  return authenticatedFetchDelete(`${baseUrl}/locations/${locationId}/${sceneId}`);
};

export const locationApi = {
  getLocations,
  createLocation,
  updateLocation,
  deleteLocation,
  addLocationToScene,
  removeLocationFromScene
};
