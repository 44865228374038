import produce from "immer";
import { itemActionTypes } from "../actions/actionTypes";
import { ReduxAction, ReduxItemState } from "../types";

const initialCharacterState: ReduxItemState = {
  items: []
};

export default function (state: ReduxItemState = initialCharacterState, action: ReduxAction) {
  switch (action.type) {
    case itemActionTypes.SET_ITEMS:
      return produce(state, (draftState) => {
        draftState.items = action.payload.items;
      });

    case itemActionTypes.CLEAR_ITEMS:
      return produce(state, (draftState) => {
        draftState.items = [];
      });
    case itemActionTypes.ADD_ITEM:
      return produce(state, (draftState) => {
        draftState.items.push(action.payload.item);
      });
    case itemActionTypes.DELETE_ITEM:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.filter((item) => item.id !== action.payload.item.id);
      });
    case itemActionTypes.UPDATE_ITEM:
      return produce(state, (draftState) => {
        draftState.items = draftState.items.map((item) => {
          if (item.id === action.payload.item.id) {
            return action.payload.item;
          }
          return item;
        });
      });
    default:
      return state;
  }
}
