import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { userApi } from "../../api";
import { Input, Button, Popin } from "../../components/controls";
import { addMessageAsync, deleteToken } from "../../redux/actions";
import { MessageType } from "../../redux/types";
import { ROUTES } from "../../utils";
import styling from "./password-reset.module.scss";

interface PasswordResetParams {
  passwordID: string;
}

const lowerCaseLetter = /[a-z]/g;
const upperCaseLetter = /[A-Z]/g;
const numberLetter = /[0-9]/g;

const PasswordReset = () => {
  const { passwordID } = useParams<PasswordResetParams>();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [passwordDuplicate, setPasswordDuplicate] = useState("");
  const dispatch = useDispatch();

  const resetPassword = async () => {
    console.log("Reset password: ", password);
    console.log("Password Valid: ", isValidPassword(password));

    if (isValidPassword(password)) {
      const passwordUpdated = await userApi.resetPassword(passwordID, password);

      if (passwordUpdated.statusOk) {
        dispatch(
          addMessageAsync(
            MessageType.INFO,
            "Your password was successfully updated. You can now log in with your new password."
          )
        );
        dispatch(deleteToken());
        history.push(ROUTES.LOGIN);
      } else {
        dispatch(
          addMessageAsync(
            MessageType.ERROR,
            "Error updating your password: " + passwordUpdated?.error,
            false,
            "Password Reset Failed"
          )
        );
      }
    } else {
      dispatch(
        addMessageAsync(
          MessageType.WARNING,
          "Your password is not valid. Please add at least one upper case letter, one lower case letter and a number. Please also make sure, that both password inputs are identical.",
          false,
          "Password invalid"
        )
      );
    }
  };

  const isValidPassword = (password: string) => {
    return (
      password.length > 5 &&
      password.length < 20 &&
      !!password.match(lowerCaseLetter) &&
      !!password.match(upperCaseLetter) &&
      !!password.match(numberLetter) &&
      password === passwordDuplicate
    );
  };

  return (
    <div className={styling.PasswordReset}>
      <Popin title="Reset your password">
        <div className={styling.container}>
          <Input
            value={password}
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}"
            borderless={true}
            label="Password"
            placeholder="Type in your password"
            onChange={(value) => setPassword(value)}
          />
          <Input
            value={passwordDuplicate}
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}"
            borderless={true}
            label="Password"
            placeholder="Type in your password"
            onChange={(value) => setPasswordDuplicate(value)}
          />
          <Button label="Reset Password" onClick={resetPassword} />
        </div>
      </Popin>
    </div>
  );
};

export default PasswordReset;
