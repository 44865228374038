export enum ROUTES {
  HOME = "/",
  DASHBOARD = "/dashboard",
  LOGIN = "/login",
  STORY = "/story/:storyId",
  CHARACTER_OVERVIEW = "/story/:storyId/characters",
  LOCATION_OVERVIEW = "/story/:storyId/locations",
  ITEM_OVERVIEW = "/story/:storyId/items",
  SETTINGS = "/settings",
  PLAYGROUND = "/playground",
  PASSWORD_RESET = "/reset-password",
  FORGOT_PASSWORD = "/forgot-password"
}
