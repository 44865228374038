import React, { FunctionComponent, useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button, BUTTON_SIZE, Input, NumberInput } from "../../components/controls";
import { Character } from "../../redux/types";
import style from "./character-form.module.scss";

export interface CharacterFormProps {
  onSave: (character: Partial<Character>) => void;
  character?: Character;
}

export const CharacterForm: FunctionComponent<CharacterFormProps> = ({ onSave, character }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [age, setAge] = useState<number>(0);
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (character) {
      setFirstName(character.firstName);
      setLastName(character.lastName);
      setAge(character.age);
      setDescription(character.description);
    }
  }, [character]);

  return (
    <div className={style.CharacterForm}>
      <div className={style.values}>
        <Input
          label="First name"
          value={firstName}
          placeholder="Type first name here"
          onChange={(value: string) => setFirstName(value)}
        />
        <Input
          label="Last name"
          value={lastName}
          placeholder="Type last name here"
          onChange={(value: string) => setLastName(value)}
        />
        <NumberInput
          label="Age"
          value={age}
          placeholder="Type the age here"
          onChange={(value: number) => setAge(value)}
        />
        <label>Description</label>
        <TextareaAutosize
          className={style.content}
          value={description}
          minRows={5}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
        />
      </div>

      <div className={style.footer}>
        <Button
          label="Save"
          stretch={true}
          size={BUTTON_SIZE.BIG}
          onClick={() =>
            onSave({
              firstName,
              lastName,
              age,
              description
            })
          }
        />
      </div>
    </div>
  );
};
