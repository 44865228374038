import produce from "immer";
import { messageActionTypes } from "../actions/actionTypes";
import { ReduxAction, Message, ReduxMessageState } from "../types";

const initialMessageState: ReduxMessageState = {
  messages: [],
};

export default function (
  state: ReduxMessageState = initialMessageState,
  action: ReduxAction
) {
  switch (action.type) {
    case messageActionTypes.ADD_MESSAGE:
      return produce(state, (draftState) => {
        draftState.messages.push({ ...action.payload });
      });

    case messageActionTypes.REMOVE_MESSAGE:
      return produce(state, (draftState) => {
        draftState.messages = draftState.messages.filter(
          (message: Message) => message.id !== action.payload.id
        );
      });

    case messageActionTypes.CLEAR_MESSAGES:
      return produce(state, (draftState) => {
        draftState.messages = [];
      });

    default:
      return state;
  }
}
