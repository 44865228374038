import React, { FunctionComponent } from "react";
import style from "./login.module.scss";
import LoginForm from "../../components/building-blocks/login-screen/login-screen";
import { Switch } from "../../components/controls";
import RegisterForm from "../../components/building-blocks/register-form/register-form";
import { useDispatch } from "react-redux";
import { loginUserAsync, registerUserAsync } from "../../redux/actions";

export const Login: FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleLoginSubmit = (email: string, password: string) => {
    dispatch(loginUserAsync(email, password));
  };

  const handleRegisterSubmit = (email: string, password: string) => {
    dispatch(registerUserAsync(email, password));
  };

  return (
    <div className={style.Login}>
      <Switch options={["Login", "Register"]}>
        <LoginForm
          onLogin={(email: string, password: string) => {
            handleLoginSubmit(email, password);
          }}
        />
        <RegisterForm
          onRegister={(email: string, password: string) => {
            handleRegisterSubmit(email, password);
          }}
        />
      </Switch>
    </div>
  );
};
