import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, BUTTON_SIZE, Modal } from "../../components/controls";
import { NewStoryForm } from "../../forms";
import { createNewStoryAsync, deleteStoryAsync, getStoriesAsync } from "../../redux/actions";
import { StorySelector } from "../../redux/selectors";
import { Story } from "../../redux/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import style from "./story-dashboard.module.scss";
import { useHistory, useLocation } from "react-router-dom";

export const StoryDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const stories: Story[] = useSelector(StorySelector);

  useEffect(() => {
    // Load User Stories
    dispatch(getStoriesAsync());
  }, []);

  const handleNew = () => {
    setShowModal(true);
  };

  const createNewStory = (story: Partial<Story>) => {
    dispatch(createNewStoryAsync(story.name!));
    setShowModal(false);
  };

  const deleteStory = (event: React.MouseEvent, story: Partial<Story>) => {
    event.stopPropagation();
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure about deleting your story? Doing this will delete all content within that story."
    );
    if (!!confirmed) {
      dispatch(deleteStoryAsync(story.id!));
    }
  };

  const openStory = (storyId: string) => {
    history.push(`/story/${storyId}`);
  };

  return (
    <div className={style.StoryDashboard}>
      <h1>Your Stories</h1>
      <Button size={BUTTON_SIZE.BIG} label="New Story" onClick={handleNew} />
      <div className={style.stories}>
        {stories &&
          stories.map((story: Story, idx: number) => (
            <span key={`story-${idx}`} className={style.story} onClick={() => openStory(story.id)}>
              <span className={style.label}>{story.name}</span>
              <span onClick={(e) => deleteStory(e, story)}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </span>
          ))}
      </div>
      <Modal title="Create new story" show={showModal} onExit={() => setShowModal(false)}>
        <NewStoryForm onSave={(story: Partial<Story>) => createNewStory(story)} />
      </Modal>
    </div>
  );
};
