import React, { useEffect, useState } from "react";
import style from "./App.module.scss";
import { Login } from "./pages/login/login";
import { useDispatch, useSelector } from "react-redux";
import { GlobalReduxState } from "./redux/types";
import { LOCAL_STORAGE_KEY_TOKEN, ROUTES } from "./utils";
import { Route } from "react-router-dom";
import { withRouter, RouteComponentProps, Switch, useLocation, Redirect } from "react-router";
import { StoryDashboard, SceneDashboard, CharacterDashboard } from "./pages";
import { MessageDisplay } from "./components/controls/message-display/MessageDisplay";
import { Navigation } from "./components/building-blocks";
import { LocationDashboard } from "./pages/location-dashboard/location-dashboard";
import { ItemDashboard } from "./pages/item-dashboard/item-dashboard";
import { userApi } from "./api";
import { setToken } from "./redux/actions";

const App: React.FC<RouteComponentProps> = ({ history }): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const userStateToken = useSelector((state: GlobalReduxState) => state.user.token);
  const location = useLocation();

  useEffect(() => {
    const userToken = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
    if (userToken) {
      userApi.verifyToken(userToken).then((res: any) => {
        if (res.verified && res.statusOk) {
          localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, userToken);
          dispatch(setToken(userToken));
          if (location.pathname === ROUTES.LOGIN) {
            history.push(ROUTES.DASHBOARD);
          }
        } else {
          localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
          history.push(ROUTES.LOGIN);
        }
        setIsLoading(false);
      });
    } else {
      history.push(ROUTES.LOGIN);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userStateToken && !isLoading && location.pathname === ROUTES.LOGIN) {
      history.push(ROUTES.DASHBOARD);
    } else if (!userStateToken && !isLoading) {
      history.push(ROUTES.LOGIN);
    }
  }, [userStateToken]);

  return (
    <div className={style.App}>
      {!isLoading && userStateToken && <Navigation />}
      <div className={style.content}>
        {isLoading ? (
          <label>Loading...</label>
        ) : (
          <Switch>
            <Route exact path={ROUTES.HOME}>
              <Redirect to={ROUTES.DASHBOARD} />
            </Route>
            <Route path={ROUTES.LOGIN}>
              <Login />
            </Route>
            <Route exact path={ROUTES.DASHBOARD}>
              <StoryDashboard />
            </Route>
            <Route exact path={ROUTES.CHARACTER_OVERVIEW}>
              <CharacterDashboard />
            </Route>
            <Route exact path={ROUTES.LOCATION_OVERVIEW}>
              <LocationDashboard />
            </Route>
            <Route exact path={ROUTES.ITEM_OVERVIEW}>
              <ItemDashboard />
            </Route>
            <Route path={ROUTES.STORY}>
              <SceneDashboard />
            </Route>
            <Route exact path={ROUTES.SETTINGS}>
              <h2> No settings available yet</h2>
            </Route>
            <Route> 404 Route not found</Route>
          </Switch>
        )}
      </div>
    </div>
  );
};

export default withRouter(App);
