import { faArrowLeft, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CharacterForm } from "../../../forms";
import { CharacterList } from "../../../pages/character-dashboard/character-list/character-list";
import {
  addCharacterToSceneAsync,
  createCharacterAsync,
  removeCharacterFromSceneAsync
} from "../../../redux/actions/characterActions";
import { CharacterSelector } from "../../../redux/selectors";
import { Character, Scene } from "../../../redux/types";
import { Button, BUTTON_SIZE, Modal } from "../../controls";
import style from "./character-container.module.scss";

interface ICharacterContainer {
  scene: Scene;
  storyId: string;
}

export const CharacterContainer: FunctionComponent<ICharacterContainer> = ({ scene, storyId }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showNewCharModal, setShowNewCharModal] = useState(false);
  const dispatch = useDispatch();

  const characters = useSelector(CharacterSelector);

  const sceneCharacters = characters.filter((char) => {
    return scene.characters.indexOf(char.id) > -1;
  });

  const handleAddCharacter = (character: Character) => {
    dispatch(addCharacterToSceneAsync(scene.id, character.id));
    setShowSearch(false);
  };

  const handleRemoveCharacter = (character: Character) => {
    dispatch(removeCharacterFromSceneAsync(scene.id, character.id));
  };

  const createNewCharacter = (character: Partial<Character>) => {
    dispatch(createCharacterAsync(storyId, character));
    setShowNewCharModal(false);
  };

  return (
    <div className={style.CharacterContainer}>
      {!showSearch ? (
        <div className={style.addedList}>
          <Button
            roundedCorners={true}
            label="Add Character to List"
            onClick={() => setShowSearch(true)}
            size={BUTTON_SIZE.BIG}
            icon={faPlus}
            className={style.addButton}
          />
          <div className={style.list}>
            {sceneCharacters &&
              sceneCharacters.map((char) => (
                <div className={style.character} key={`scene-character-${char.id}`}>
                  <span>
                    {char.firstName} {char.lastName}
                  </span>
                  <label
                    onClick={() => handleRemoveCharacter(char)}
                    className={style.remove}
                    title="Remove Character from scene"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </label>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className={style.searchList}>
          <div className={style.buttons}>
            <Button
              roundedCorners={true}
              className={style.button}
              size={BUTTON_SIZE.BIG}
              icon={faArrowLeft}
              label="Zurück"
              onClick={() => setShowSearch(false)}
            />
            <Button
              roundedCorners={true}
              className={style.button}
              size={BUTTON_SIZE.BIG}
              icon={faPlus}
              stretch={true}
              label="Create new Character"
              onClick={() => setShowNewCharModal(true)}
            />
          </div>
          <CharacterList
            storyId={storyId}
            selection={true}
            filterFunc={(char) => scene.characters.indexOf(char.id) === -1}
            onSelectCharacter={(character: Character) => handleAddCharacter(character)}
          />
          <Modal title="Create new character" show={showNewCharModal} onExit={() => setShowNewCharModal(false)}>
            <CharacterForm onSave={(character: Partial<Character>) => createNewCharacter(character)} />
          </Modal>
        </div>
      )}
    </div>
  );
};
