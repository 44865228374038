import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { updateSceneAsync } from "../../../redux/actions";
import { Scene } from "../../../redux/types";
import { useDebounce } from "../../../utils";
import style from "./scene-content.module.scss";

interface ISceneContent {
  scene: Scene;
  storyId: string;
}

export const SceneContent: FunctionComponent<ISceneContent> = ({ storyId, scene }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    setValue(scene.content);
  }, [scene.content]);

  const debouncedValue = useDebounce(value, 2000);

  useEffect(() => {
    if (scene.content !== value && value !== undefined) {
      dispatch(updateSceneAsync(storyId, { ...scene, content: debouncedValue }));
    }
  }, [debouncedValue]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className={style.SceneContent}>
      <TextareaAutosize className={style.content} value={value} onChange={handleChange} />
    </div>
  );
};
