import React, { useEffect, useState } from "react";
import { ServerResponse } from "../../api";
import { useParams } from "react-router-dom";
import { userApi } from "../../api";
import { Input, Popin, Button } from "../../components/controls";
import { emailRegex } from "../../utils/regex";
import styling from "./forgot-password.module.scss";
import { useDispatch } from "react-redux";
import { addMessageAsync } from "../../redux/actions";
import { MessageType } from "../../redux/types";

interface ForgotPasswordParams {
  email: string;
}

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const params = useParams<ForgotPasswordParams>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.email) {
      setEmail(params.email);
    }
  }, [params.email]);

  const handleResetEmail = async () => {
    if (email && !!email.match(emailRegex)) {
      const result: ServerResponse = await userApi.requestResetPassword(email);
      if (result.statusOk) {
        dispatch(addMessageAsync(MessageType.INFO, "Check your email for your reset link", false, "It worked!"));
      } else {
        dispatch(
          addMessageAsync(
            MessageType.ERROR,
            result?.error ?? "Unknown error, please contact support",
            false,
            "Error during password reset request"
          )
        );
      }
    }
  };

  return (
    <div className={styling.ForgotPassword}>
      <Popin title="Forgot password">
        <div className={styling.container}>
          <Input
            placeholder="Please enter your email address"
            borderless={true}
            value={email}
            onChange={(value) => setEmail(value)}
            label="Email"
          />
          <Button label="Request Reset per Email" onClick={handleResetEmail} />
        </div>
      </Popin>
    </div>
  );
};

export default ForgotPassword;
