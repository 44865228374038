import { ThunkDispatch } from "redux-thunk";
import { itemApi, ServerResponse } from "../../api";
import { Item, MessageType } from "../types";
import { itemActionTypes } from "./actionTypes";
import { addMessageAsync } from "./messageActions";
import { addItemToScene, removeItemFromScene } from "./sceneActions";

export const setItems = (items: Item[]) => ({
  type: itemActionTypes.SET_ITEMS,
  payload: {
    items
  }
});

export const clearItems = () => ({
  type: itemActionTypes.CLEAR_ITEMS
});

export const addItem = (item: Item) => ({
  type: itemActionTypes.ADD_ITEM,
  payload: {
    item
  }
});

export const updateItem = (item: Partial<Item>) => ({
  type: itemActionTypes.UPDATE_ITEM,
  payload: {
    item
  }
});

export const deleteItem = (item: Item) => ({
  type: itemActionTypes.DELETE_ITEM,
  payload: {
    item
  }
});

export const getItemsAsync = (storyId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result: ServerResponse = await itemApi.getItems(storyId);

  if (result.statusOk) {
    dispatch(setItems(result.data));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, `Error while loading items: ${result?.error}`));
  }
};

export const createItemAsync =
  (storyId: string, item: Partial<Item>) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await itemApi.createItem(storyId, item);

    if (result.statusOk) {
      dispatch(addItem(result.data));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while creating item: ${result?.error}`));
    }
  };

export const updateItemAsync =
  (storyId: string, item: Partial<Item>) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await itemApi.updateItem(storyId, item);

    if (result.statusOk) {
      dispatch(updateItem(item));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while updating item: ${result?.error}`));
    }
  };

export const deleteItemAsync = (item: Item) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result: ServerResponse = await itemApi.deleteItem(item);

  if (result.statusOk) {
    dispatch(deleteItem(item));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, `Error while deleting a item: ${result?.error}`));
  }
};

export const addItemToSceneAsync =
  (sceneId: string, itemId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await itemApi.addItemToScene(sceneId, itemId);

    if (result.statusOk) {
      dispatch(addItemToScene(sceneId, itemId));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while adding item to scene: ${result?.error}`));
    }
  };

export const removeItemFromSceneAsync =
  (sceneId: string, itemId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await itemApi.removeItemFromScene(sceneId, itemId);

    if (result.statusOk) {
      dispatch(removeItemFromScene(sceneId, itemId));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while adding item to scene: ${result?.error}`));
    }
  };
