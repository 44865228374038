import { LOCAL_STORAGE_KEY_TOKEN } from "../utils";

export const baseUrl = process.env.REACT_APP_API_URL;

export interface ServerResponse extends Response {
  statusOk: boolean;
  data?: any;
  error?: string;
}

export const fetchPost = (url: string, body: any): Promise<any> => {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify(body)
  })
    .then((res) => res.json())
    .catch((error) => console.error(error));
};

export const fetchGet = (url: string): Promise<any> => {
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  })
    .then((res) => res.json())
    .catch((error) => console.error(error));
};

export const authenticatedFetchGet = (url: string, body?: any): Promise<any> => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`
  };
  return fetch(url, {
    method: "GET",
    headers,
    body: body ? JSON.stringify(body) : undefined
  }).then((res) => res.json());
};

export const authenticatedFetchPost = (url: string, body?: any): Promise<any> => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`
  };
  return fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  }).then((res) => res.json());
};

export const authenticatedFetchPut = (url: string, body: any): Promise<any> => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`
  };

  return fetch(url, {
    method: "PUT",
    headers,
    body: JSON.stringify(body)
  }).then((res) => res.json());
};

export const authenticatedFetchDelete = (url: string, body?: any): Promise<any> => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`
  };
  return fetch(url, {
    method: "DELETE",
    headers,
    body: body ? JSON.stringify(body) : undefined
  }).then((res) => res.json());
};

export * from "./userApi";
export * from "./storyApi";
export * from "./sceneApi";
export * from "./characterApi";
export * from "./locationApi";
export * from "./itemApi";
