import React, { FunctionComponent } from "react";
import styling from "./popin.module.scss";

interface PopinProps {
  title: string;
  children: React.ReactElement | React.ReactElement[];
}

export const Popin: FunctionComponent<PopinProps> = ({ children, title }) => {
  return (
    <div className={styling.Popin}>
      <div className={styling.title}>{title}</div>
      <div className={styling.content}>{children}</div>
    </div>
  );
};
