import React, { FunctionComponent, useState } from "react";
import { Button, BUTTON_SIZE, Input } from "../../components/controls";
import { Story } from "../../redux/types";
import style from "./new-story-form.module.scss";

export interface NewStoryFormProps {
  onSave: (story: Partial<Story>) => void;
}

export const NewStoryForm: FunctionComponent<NewStoryFormProps> = (props) => {
  const [name, setName] = useState("");

  return (
    <div className={style.NewStoryForm}>
      <div className={style.values}>
        <Input
          label="Story title"
          value={name}
          placeholder="Type your title here"
          onChange={(value: string) => setName(value)}
        />
      </div>

      <div className={style.footer}>
        <Button
          label="Save"
          stretch={true}
          size={BUTTON_SIZE.BIG}
          onClick={() =>
            props.onSave({
              name
            })
          }
        />
      </div>
    </div>
  );
};
