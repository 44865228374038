import React, { ChangeEvent } from "react";
import style from "./input.module.scss";
import classNames from "classnames";

interface InputProps {
  className?: any;
  onChange: (value: string) => void;
  value: string;
  label?: string;
  placeholder?: string;
  password?: boolean;
  borderless?: boolean;
  pattern?: string;
}

export const Input: React.FunctionComponent<InputProps> = (props) => {
  return (
    <div
      className={classNames(
        style.Input,
        {
          [style.Input__borderless]: props.borderless
        },
        props.className
      )}
      aria-label="input-wrapper"
    >
      <label className={style.label} aria-label={props.label}>
        {props.label}
      </label>
      <input
        type={props.password ? "password" : "text"}
        className={style.input}
        aria-label={props.label}
        pattern={props.pattern ?? undefined}
        placeholder={props.placeholder}
        onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
        value={props.value}
      />
    </div>
  );
};
