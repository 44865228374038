import {
  baseUrl,
  authenticatedFetchGet,
  authenticatedFetchPost,
  authenticatedFetchPut,
  authenticatedFetchDelete
} from ".";
import { Character } from "../redux/types";

const getCharacters = (storyId: string) => {
  return authenticatedFetchGet(`${baseUrl}/characters/${storyId}`);
};

const createCharacter = (storyId: string, character: Partial<Character>) => {
  return authenticatedFetchPost(`${baseUrl}/characters`, { storyId, character });
};

const updateCharacter = (storyId: string, character: Partial<Character>) => {
  return authenticatedFetchPut(`${baseUrl}/characters`, { storyId, character });
};

const deleteCharacter = (character: Character) => {
  return authenticatedFetchDelete(`${baseUrl}/characters/${character.id}`);
};

const addCharacterToScene = (sceneId: string, characterId: string) => {
  return authenticatedFetchPost(`${baseUrl}/characters/${characterId}/${sceneId}`);
};

const removeCharacterFromScene = (sceneId: string, characterId: string) => {
  return authenticatedFetchDelete(`${baseUrl}/characters/${characterId}/${sceneId}`);
};

export const characterApi = {
  getCharacters,
  createCharacter,
  updateCharacter,
  deleteCharacter,
  addCharacterToScene,
  removeCharacterFromScene
};
