import produce from "immer";
import { storyActionTypes } from "../actions/actionTypes";
import { ReduxStoryState, ReduxAction } from "../types";

const initialStoryState: ReduxStoryState = {
  stories: [],
};

export default function (
  state: ReduxStoryState = initialStoryState,
  action: ReduxAction
) {
  switch (action.type) {
    case storyActionTypes.SET_STORIES:
      return produce(state, (draftState) => {
        draftState.stories = action.payload.stories;
      });
    default:
      return state;
  }
}
