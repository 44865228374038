import React, { FunctionComponent, useEffect, useState } from "react";
import style from "./scene.module.scss";
import classNames from "classnames";
import { Button, Tab, TabContainer } from "../../controls";
import { useDispatch } from "react-redux";
import { deleteSceneAsync, toggleShrinkHeadspace, updateSceneAsync } from "../../../redux/actions";
import { Scene as SceneType } from "../../../redux/types";
import { Draggable, DraggableStateSnapshot } from "react-beautiful-dnd";
import {
  faPlus,
  faAddressBook,
  faBookOpen,
  faDiceD6,
  faTrash,
  faArrowsAltV,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import { SceneContent } from "../scene-content/scene-content";
import { CharacterContainer } from "../character-container/character-container";
import { LocationContainer } from "../location-container/location-container";
import { ItemContainer } from "../item-container/item-container";
import { useDebounce } from "../../../utils";

interface SceneProps {
  storyId: string;
  scene: SceneType;
  draggableIndex: number;
  hideButtons: boolean;
  onAddScene: (sceneIndex: number) => void;
}

export const Scene: FunctionComponent<SceneProps> = ({ scene, draggableIndex, hideButtons, onAddScene, storyId }) => {
  const [showButtons, toggleShowButtons] = useState(false);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(scene.title);
  }, [scene.title]);

  const debouncedTitle = useDebounce(title, 2000);

  useEffect(() => {
    if (scene.title !== title && title !== undefined) {
      dispatch(updateSceneAsync(storyId, { ...scene, title }));
    }
  }, [debouncedTitle]);

  const onMouseEnter = () => {
    toggleShowButtons(true);
    if (!hideButtons) dispatch(toggleShrinkHeadspace(true));
  };

  const onMouseLeave = () => {
    toggleShowButtons(false);
    if (!hideButtons) dispatch(toggleShrinkHeadspace(false));
  };

  const showAddButtons = (snapshot: DraggableStateSnapshot) => {
    return !hideButtons && showButtons && !snapshot.isDragging;
  };

  const handleDeleteScene = () => {
    const confirm = window.confirm("Do you want to permanently delete this scene?");
    if (!!confirm) {
      dispatch(deleteSceneAsync(storyId, scene));
    }
  };

  return (
    <Draggable index={draggableIndex} key={`${scene.sceneIndex}`} draggableId={`${scene.sceneIndex}`}>
      {(provided, snapshot) => (
        <div
          className={style.Scene}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          key={`scene-key-${draggableIndex}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Button
            className={classNames(style.addButton, style.addButton__top, {
              [style.addButton__show]: showAddButtons(snapshot)
            })}
            round
            icon={faPlus}
            onClick={() => onAddScene(scene.sceneIndex)}
          />
          <TabContainer
            className={style.tabContainer}
            tabFunctions={[
              {
                label: "Delete",
                icon: faTrash,
                onClick: handleDeleteScene
              },
              {
                label: "Move",
                icon: faArrowsAltV,
                handleProps: provided.dragHandleProps ?? undefined
              }
            ]}
          >
            <Tab title={title} onChange={(value: string) => setTitle(value)} icon={faBookOpen}>
              <SceneContent scene={scene} storyId={storyId} />
            </Tab>
            <Tab label="Characters" icon={faAddressBook}>
              <CharacterContainer scene={scene} storyId={storyId} />
            </Tab>
            <Tab label="Locations" icon={faMapMarkerAlt}>
              <LocationContainer scene={scene} storyId={storyId} />
            </Tab>
            <Tab label="Items" icon={faDiceD6}>
              <ItemContainer scene={scene} storyId={storyId} />
            </Tab>
          </TabContainer>
          <Button
            className={classNames(style.addButton, style.addButton__bottom, {
              [style.addButton__show]: showAddButtons(snapshot)
            })}
            round
            icon={faPlus}
            onClick={() => onAddScene(scene.sceneIndex + 1)}
          />
        </div>
      )}
    </Draggable>
  );
};
