import produce from "immer";
import { sceneActionTypes } from "../actions/actionTypes";
import { ReduxAction, ReduxSceneState } from "../types";

const initialSceneState: ReduxSceneState = {
  scenes: []
};

export default function (state: ReduxSceneState = initialSceneState, action: ReduxAction) {
  switch (action.type) {
    case sceneActionTypes.SET_SCENES:
      return produce(state, (draftState) => {
        draftState.scenes = action.payload.scenes;
      });
    case sceneActionTypes.UPDATE_SCENE:
      return produce(state, (draftState) => {
        draftState.scenes = draftState.scenes.map((scene) => {
          if (scene.id === action.payload.scene.id) {
            return action.payload.scene;
          } else {
            return scene;
          }
        });
      });
    case sceneActionTypes.DELETE_SCENE:
      return produce(state, (draftState) => {
        const deletedIndex = action.payload.scene.sceneIndex;
        draftState.scenes = draftState.scenes.filter((scene) => scene.id !== action.payload.scene.id);
        draftState.scenes = draftState.scenes.map((scene) => {
          if (scene.sceneIndex > deletedIndex) {
            return {
              ...scene,
              sceneIndex: scene.sceneIndex - 1
            };
          } else {
            return scene;
          }
        });
      });

    case sceneActionTypes.MOVE_SCENE:
      return produce(state, (draftState) => {
        const { scene, newIndex } = action.payload;

        const oldIndex = scene.sceneIndex;

        draftState.scenes = draftState.scenes.filter((sc) => sc.id !== scene.id);
        draftState.scenes.splice(newIndex, 0, { ...scene, sceneIndex: newIndex });

        if (oldIndex > newIndex) {
          draftState.scenes = draftState.scenes.map((sc) => {
            if (sc.sceneIndex >= newIndex && sc.sceneIndex < oldIndex && sc.id !== scene.id) {
              return { ...sc, sceneIndex: sc.sceneIndex + 1 };
            } else {
              return sc;
            }
          });
        } else if (oldIndex < newIndex) {
          draftState.scenes = draftState.scenes.map((sc) => {
            if (sc.sceneIndex > oldIndex && sc.sceneIndex <= newIndex && sc.id !== scene.id) {
              return { ...sc, sceneIndex: sc.sceneIndex - 1 };
            } else {
              return sc;
            }
          });
        }
      });
    case sceneActionTypes.ADD_CHARACTER_TO_SCENE:
      return produce(state, (draftState) => {
        const { sceneId, characterId } = action.payload;

        draftState.scenes = draftState.scenes.map((scene) => {
          if (scene.id === sceneId) {
            scene.characters.push(characterId);
          }
          return scene;
        });
      });
    case sceneActionTypes.REMOVE_CHARACTER_FROM_SCENE:
      return produce(state, (draftState) => {
        const { sceneId, characterId } = action.payload;
        draftState.scenes = draftState.scenes.map((scene) => {
          if (scene.id === sceneId) {
            scene.characters = scene.characters.filter((charId) => charId !== characterId);
          }
          return scene;
        });
      });
    case sceneActionTypes.ADD_LOCATION_TO_SCENE:
      return produce(state, (draftState) => {
        const { sceneId, locationId } = action.payload;

        draftState.scenes = draftState.scenes.map((scene) => {
          if (scene.id === sceneId) {
            scene.locations.push(locationId);
          }
          return scene;
        });
      });
    case sceneActionTypes.REMOVE_LOCATION_FROM_SCENE:
      return produce(state, (draftState) => {
        const { sceneId, locationId } = action.payload;
        draftState.scenes = draftState.scenes.map((scene) => {
          if (scene.id === sceneId) {
            scene.locations = scene.locations.filter((locId) => locId !== locationId);
          }
          return scene;
        });
      });
    case sceneActionTypes.ADD_ITEM_TO_SCENE:
      return produce(state, (draftState) => {
        const { sceneId, itemId } = action.payload;

        draftState.scenes = draftState.scenes.map((scene) => {
          if (scene.id === sceneId) {
            scene.items.push(itemId);
          }
          return scene;
        });
      });
    case sceneActionTypes.REMOVE_ITEM_FROM_SCENE:
      return produce(state, (draftState) => {
        const { sceneId, itemId } = action.payload;
        draftState.scenes = draftState.scenes.map((scene) => {
          if (scene.id === sceneId) {
            scene.items = scene.items.filter((iId) => iId !== itemId);
          }
          return scene;
        });
      });
    default:
      return state;
  }
}
