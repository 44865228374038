import { faArrowLeft, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocationForm } from "../../../forms";
import { LocationList } from "../../../pages/location-dashboard/location-list/location-list";
import {
  addLocationToSceneAsync,
  createLocationAsync,
  removeLocationFromSceneAsync
} from "../../../redux/actions/locationActions";
import { LocationSelector } from "../../../redux/selectors";
import { Location, Scene } from "../../../redux/types";
import { Button, BUTTON_SIZE, Modal } from "../../controls";
import style from "./location-container.module.scss";

interface ILocationContainer {
  scene: Scene;
  storyId: string;
}

export const LocationContainer: FunctionComponent<ILocationContainer> = ({ scene, storyId }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showNewCharModal, setShowNewCharModal] = useState(false);
  const dispatch = useDispatch();

  const locations = useSelector(LocationSelector);

  console.log("Locations: ", locations);

  const sceneLocations = locations.filter((char) => {
    console.log("Location: ", char);
    return scene.locations.indexOf(char.id) > -1;
  });

  const handleAddLocation = (location: Location) => {
    dispatch(addLocationToSceneAsync(scene.id, location.id));
    setShowSearch(false);
  };

  const handleRemoveLocation = (location: Location) => {
    dispatch(removeLocationFromSceneAsync(scene.id, location.id));
  };

  const createNewLocation = (location: Partial<Location>) => {
    dispatch(createLocationAsync(storyId, location));
    setShowNewCharModal(false);
  };

  return (
    <div className={style.LocationContainer}>
      {!showSearch ? (
        <div className={style.addedList}>
          <Button
            roundedCorners={true}
            label="Add Location to List"
            onClick={() => setShowSearch(true)}
            size={BUTTON_SIZE.BIG}
            icon={faPlus}
            className={style.addButton}
          />
          <div className={style.list}>
            {sceneLocations &&
              sceneLocations.map((char) => (
                <div className={style.location} key={`scene-location-${char.id}`}>
                  <span>{char.name}</span>
                  <label
                    onClick={() => handleRemoveLocation(char)}
                    className={style.remove}
                    title="Remove Location from scene"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </label>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className={style.searchList}>
          <div className={style.buttons}>
            <Button
              roundedCorners={true}
              className={style.button}
              size={BUTTON_SIZE.BIG}
              icon={faArrowLeft}
              label="Zurück"
              onClick={() => setShowSearch(false)}
            />
            <Button
              roundedCorners={true}
              className={style.button}
              size={BUTTON_SIZE.BIG}
              icon={faPlus}
              stretch={true}
              label="Create new Location"
              onClick={() => setShowNewCharModal(true)}
            />
          </div>
          <LocationList
            storyId={storyId}
            selection={true}
            filterFunc={(char) => scene.locations.indexOf(char.id) === -1}
            onSelectLocation={(location: Location) => handleAddLocation(location)}
          />
          <Modal title="Create new location" show={showNewCharModal} onExit={() => setShowNewCharModal(false)}>
            <LocationForm onSave={(location: Partial<Location>) => createNewLocation(location)} />
          </Modal>
        </div>
      )}
    </div>
  );
};
