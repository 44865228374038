import { createStore, applyMiddleware, compose, Store } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const mode = process.env.REACT_APP_MODE;
let composeEnhancers;
if (mode === "PRODUCTION") {
  composeEnhancers = compose;
} else {
  // @ts-ignore
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store: Store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
