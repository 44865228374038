import produce from "immer";
import { locationActionTypes } from "../actions/actionTypes";
import { ReduxAction, ReduxLocationState } from "../types";

const initialCharacterState: ReduxLocationState = {
  locations: []
};

export default function (state: ReduxLocationState = initialCharacterState, action: ReduxAction) {
  switch (action.type) {
    case locationActionTypes.SET_LOCATIONS:
      return produce(state, (draftState) => {
        draftState.locations = action.payload.locations;
      });

    case locationActionTypes.CLEAR_LOCATIONS:
      return produce(state, (draftState) => {
        draftState.locations = [];
      });
    case locationActionTypes.ADD_LOCATION:
      return produce(state, (draftState) => {
        draftState.locations.push(action.payload.location);
      });
    case locationActionTypes.DELETE_LOCATION:
      return produce(state, (draftState) => {
        draftState.locations = draftState.locations.filter((loc) => loc.id !== action.payload.location.id);
      });
    case locationActionTypes.UPDATE_LOCATION:
      return produce(state, (draftState) => {
        draftState.locations = draftState.locations.map((loc) => {
          if (loc.id === action.payload.location.id) {
            return action.payload.location;
          }
          return loc;
        });
      });
    default:
      return state;
  }
}
