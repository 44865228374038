import React, { useState, FunctionComponent, useEffect, useMemo } from "react";
import style from "./register-form.module.scss";
import { Input, Button, BUTTON_SIZE } from "../../controls";
import { useDispatch } from "react-redux";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { addMessageAsync } from "../../../redux/actions";
import { MessageType } from "../../../redux/types";
import { emailRegex } from "../../../utils/regex";

interface RegisterFormProps {
  onRegister: (email: string, password: string) => void;
}

const RegisterForm: FunctionComponent<RegisterFormProps> = (props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [verified, setVerified] = useState(false);
  const dispatch = useDispatch();

  const isDevelopment = useMemo(() => process.env.NODE_ENV === "development", []);

  const handleSubmit = () => {
    if (verified || isDevelopment) {
      if (email && !!email.match(emailRegex) && password === passwordConfirm) {
        props.onRegister(email, password);
      } else {
        dispatch(
          addMessageAsync(
            MessageType.ERROR,
            "Please verify if you're using a valid email and the passwords are the same.",
            true,
            "Email or password wrong"
          )
        );
      }
    } else {
      dispatch(
        addMessageAsync(MessageType.WARNING, "Please fill out your credentials", true, "Login or Password empty")
      );
    }
  };

  return (
    <div className={style.RegisterScreen}>
      <Input
        label="Email"
        placeholder="Enter a valid email"
        borderless={true}
        onChange={(value: string) => setEmail(value)}
        value={email}
      />
      <Input
        password={true}
        borderless={true}
        placeholder="Enter a password"
        label="Password"
        onChange={(value: string) => setPassword(value)}
        value={password}
      />
      <Input
        password={true}
        borderless={true}
        placeholder="Enter the password again"
        label="Confirm Password"
        onChange={(value: string) => setPasswordConfirm(value)}
        value={passwordConfirm}
      />
      {!isDevelopment && (
        <form name="captcha-verification" className={style.captcha}>
          <HCaptcha sitekey="59077fdc-6b8a-4b61-b6d4-2aebb47fe70c" onVerify={() => setVerified(true)} />
        </form>
      )}
      <Button label="Register" roundedCorners={true} size={BUTTON_SIZE.BIG} onClick={() => handleSubmit()} />
    </div>
  );
};

export default RegisterForm;
