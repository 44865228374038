import React, { FunctionComponent } from "react";
import style from "./function-tab.module.scss";
import { TabFunction } from "../tab-container/tab-container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export const FunctionTab: FunctionComponent<TabFunction> = (props) => {
  return (
    <div
      className={classNames(style.FunctionTab, {
        [style.FunctionTab__draggable]: !!props.handleProps,
      })}
      onClick={props.onClick}
      {...props.handleProps}
    >
      {props.icon && (
        <FontAwesomeIcon className={style.icon} icon={props.icon} />
      )}
    </div>
  );
};
