import { combineReducers } from "redux";
import navigationReducer from "./navigationReducer";
import userReducer from "./userReducer";
import messageReducer from "./messageReducer";
import storyReducer from "./storyReducer";
import sceneReducer from "./sceneReducer";
import characterReducer from "./characterReducer";
import locationReducer from "./locationReducer";
import itemReducer from "./itemReducer";

export default combineReducers({
  navigation: navigationReducer,
  user: userReducer,
  messages: messageReducer,
  stories: storyReducer,
  scenes: sceneReducer,
  characters: characterReducer,
  locations: locationReducer,
  items: itemReducer
});
