import { baseUrl, fetchGet, fetchPost } from ".";

const loginUser = (email: string, password: string) => {
  return fetchPost(`${baseUrl}/login`, { email, password });
};

const registerUser = (email: string, password: string) => {
  return fetchPost(`${baseUrl}/register`, {
    email,
    password
  });
};

const verifyToken = (token: string) => {
  return fetchPost(`${baseUrl}/verifyToken`, { token });
};

const requestResetPassword = (email: string) => {
  return fetchGet(`${baseUrl}/forgot-password/${email}`);
};

const resetPassword = (passwordID: string, password: string) => {
  return fetchPost(`${baseUrl}/reset-password/${passwordID}`, { password });
};

export const userApi = {
  loginUser,
  verifyToken,
  registerUser,
  resetPassword,
  requestResetPassword
};
