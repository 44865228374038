import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Button, BUTTON_SIZE, Modal } from "../../components/controls";
import { ItemForm } from "../../forms";
import { createItemAsync, deleteItemAsync, updateItemAsync } from "../../redux/actions/itemActions";
import { Item } from "../../redux/types";
import style from "./item-dashboard.module.scss";
import { ItemList } from "./item-list/item-list";

interface ItemDashboardRoutingParams {
  storyId: string;
}

export const ItemDashboard = () => {
  const dispatch = useDispatch();
  const { storyId } = useParams<ItemDashboardRoutingParams>();
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [itemToUpdate, setItemToUpdate] = useState<Item | undefined>(undefined);

  const createNewItem = (item: Partial<Item>) => {
    dispatch(createItemAsync(storyId, item));
    setShowModal(false);
  };

  const updateItem = (item: Partial<Item>) => {
    dispatch(updateItemAsync(storyId, { id: itemToUpdate?.id, ...item }));
    setItemToUpdate(undefined);
  };

  const handleUpdateItem = (item: Item) => {
    setItemToUpdate(item);
  };

  const handleDeleteItem = (item: Item) => {
    const confirm = window.confirm("Do you want to permanently delete this item?");
    if (!!confirm) {
      dispatch(deleteItemAsync(item));
    }
  };

  return (
    <div className={style.ItemDashboard}>
      <Button
        icon={faArrowLeft}
        label="Back to Story"
        size={BUTTON_SIZE.BIG}
        onClick={() => history.push(`/story/${storyId}`)}
      />
      <h2>Items</h2>
      <Button size={BUTTON_SIZE.BIG} icon={faPlus} label="New Item" onClick={() => setShowModal(true)} />
      <ItemList
        storyId={storyId}
        onUpdateItem={(item: Item) => handleUpdateItem(item)}
        onDeleteItem={(item: Item) => handleDeleteItem(item)}
      />
      <Modal title="Create new item" show={showModal} onExit={() => setShowModal(false)}>
        <ItemForm onSave={(item: Partial<Item>) => createNewItem(item)} />
      </Modal>
      <Modal title="Update item" show={!!itemToUpdate} onExit={() => setItemToUpdate(undefined)}>
        <ItemForm item={itemToUpdate} onSave={(item: Partial<Item>) => updateItem(item)} />
      </Modal>
    </div>
  );
};
