import React, { FunctionComponent, useState } from "react";
import style from "./switch.module.scss";
import classNames from "classnames";

interface SwitchProps {
  options: string[];
  children?: any[];
}

export const Switch: FunctionComponent<SwitchProps> = (props) => {
  const [chosenOption, setChosenOption] = useState(0);

  return (
    <div className={style.Switch} aria-label="switch">
      <div className={style.options} aria-label="optionsList">
        {props.options.map((option: string, idx: number) => (
          <div
            aria-label="option"
            key={`option-id${idx}`}
            className={classNames(style.option, {
              [style.option__chosen]: idx === chosenOption
            })}
            onClick={() => setChosenOption(idx)}
          >
            {option}
          </div>
        ))}
      </div>
      <div className={style.content} aria-label="children-container">
        {props.children && props.children[chosenOption]}
      </div>
    </div>
  );
};
