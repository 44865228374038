import { userApi } from "../../api";
import { ThunkDispatch } from "redux-thunk";
import { userActionTypes } from "./actionTypes";
import { LOCAL_STORAGE_KEY_TOKEN } from "../../utils";
import { addMessageAsync } from "./messageActions";
import { MessageType } from "../types";

export const setToken = (token: string) => {
  return {
    type: userActionTypes.SET_TOKEN,
    payload: {
      token
    }
  };
};

export const deleteToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
  return {
    type: userActionTypes.DELETE_TOKEN
  };
};

export const setTokenLoading = (isLoading: boolean) => {
  return {
    type: userActionTypes.SET_TOKEN_LOADING,
    payload: {
      isLoading
    }
  };
};

export const loginUserAsync = (email: string, password: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  return userApi.loginUser(email, password).then((res) => {
    if (res?.statusOk && res?.accessToken) {
      localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, res.accessToken);
      dispatch(setToken(res.accessToken));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, res.error));
    }
  });
};

export const registerUserAsync =
  (email: string, password: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    return userApi.registerUser(email, password).then((res) => {
      if (res.statusOk) {
        dispatch(addMessageAsync(MessageType.INFO, "Check your mail"));
      } else {
        dispatch(addMessageAsync(MessageType.ERROR, res.error));
      }
    });
  };

export const verifyTokenAsync = (token: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  return userApi.verifyToken(token).then((res) => {
    if (res.verified && res.statusOk) {
      dispatch(setToken(token));
      dispatch(setTokenLoading(false));
    } else {
      // Token invalid, timed out or fake
      localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
      dispatch(addMessageAsync(MessageType.ERROR, res.error));
      dispatch(setTokenLoading(false));
    }
  });
};
