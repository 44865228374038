import { ThunkDispatch } from "redux-thunk";
import { locationApi, ServerResponse } from "../../api";
import { Location, MessageType } from "../types";
import { locationActionTypes } from "./actionTypes";
import { addMessageAsync } from "./messageActions";
import { addLocationToScene, removeLocationFromScene } from "./sceneActions";

export const setLocations = (locations: Location[]) => ({
  type: locationActionTypes.SET_LOCATIONS,
  payload: {
    locations
  }
});

export const clearLocations = () => ({
  type: locationActionTypes.CLEAR_LOCATIONS
});

export const addLocation = (location: Location) => ({
  type: locationActionTypes.ADD_LOCATION,
  payload: {
    location
  }
});

export const updateLocation = (location: Partial<Location>) => ({
  type: locationActionTypes.UPDATE_LOCATION,
  payload: {
    location
  }
});

export const deleteLocation = (location: Location) => ({
  type: locationActionTypes.DELETE_LOCATION,
  payload: {
    location
  }
});

export const getLocationsAsync = (storyId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result: ServerResponse = await locationApi.getLocations(storyId);

  if (result.statusOk) {
    dispatch(setLocations(result.data));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, `Error while loading locations: ${result?.error}`));
  }
};

export const createLocationAsync =
  (storyId: string, location: Partial<Location>) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await locationApi.createLocation(storyId, location);

    if (result.statusOk) {
      dispatch(addLocation(result.data));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while creating location: ${result?.error}`));
    }
  };

export const updateLocationAsync =
  (storyId: string, location: Partial<Location>) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await locationApi.updateLocation(storyId, location);

    if (result.statusOk) {
      dispatch(updateLocation(location));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while updating location: ${result?.error}`));
    }
  };

export const deleteLocationAsync = (location: Location) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const result: ServerResponse = await locationApi.deleteLocation(location);

  if (result.statusOk) {
    dispatch(deleteLocation(location));
  } else {
    dispatch(addMessageAsync(MessageType.ERROR, `Error while deleting a location: ${result?.error}`));
  }
};

export const addLocationToSceneAsync =
  (sceneId: string, locationId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await locationApi.addLocationToScene(sceneId, locationId);

    if (result.statusOk) {
      dispatch(addLocationToScene(sceneId, locationId));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while adding location to scene: ${result?.error}`));
    }
  };

export const removeLocationFromSceneAsync =
  (sceneId: string, locationId: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
    const result: ServerResponse = await locationApi.removeLocationFromScene(sceneId, locationId);

    if (result.statusOk) {
      dispatch(removeLocationFromScene(sceneId, locationId));
    } else {
      dispatch(addMessageAsync(MessageType.ERROR, `Error while adding location to scene: ${result?.error}`));
    }
  };
